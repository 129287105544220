import { Injectable, inject } from '@angular/core';
import { CwtI18nService, asArray, getId, translateDictionary } from '@cawita/core-front';
import { CwtApiService } from '@cawita/core-front/api';
import { CompanyTemplateUseCase } from '@shared/models';
import { MailActionHandler, MailPreviewOptions, MailSendOptions } from '@shared/modules/mailing';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SendConformityMailAdapter extends MailActionHandler {
    protected api = inject(CwtApiService);
    protected i18n = inject(CwtI18nService);

    getTemplatesUseCases() { return [CompanyTemplateUseCase.SendCustomerConformityEvent]; }

    preview(options: MailPreviewOptions): Observable<any> {
        const template = options.template;
        return of({
            parsedSubject: translateDictionary(template.subject, this.i18n.currentLocale),
            parsedContent: translateDictionary(template.content, this.i18n.currentLocale),
        });
    }

    send(options: MailSendOptions): Observable<any> {
        return this.api.post(`company/:companyId/conformity/conformity-email`, {
            emails: options.emails,
            template: getId(options.template),
            content: options.content,
            subject: options.subject,
            customers: asArray(options['customers']),
            conformityTemplates: asArray(options['conformityTemplates'])
        });
    }
}