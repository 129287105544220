import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { CWT_FORM_CONFIG, ICwtFormConfig } from '@cawita/core-front/form';
import { isset } from '@hints/utils/data';
import { hasSomeChangesInIds } from '@shared/utils';
import { provideValidator, provideValueAccessor } from '@shared/widgets/features/common/simple-value-accessor/simple-value-accessor.directive';
import { Observable } from 'rxjs';
import { Customer, CustomerContact, User } from '../../../models';
import { MailRecipient } from '../mailing-types';
import { MailRecipientsService } from '../services/mail-recipients.service';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { BooleanInput } from '@angular/cdk/coercion';

export type MailRecipientLike = MailRecipient<any> | string;

@Component({
  selector: 'cwt-mail-recipients-input',
  templateUrl: './mail-recipients-input.component.html',
  styleUrls: ['./mail-recipients-input.component.less'],
  providers: [
    provideValueAccessor(MailRecipientsInputComponent),
    provideValidator(MailRecipientsInputComponent),
  ]
})
export class MailRecipientsInputComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() @InputBoolean() nzBorderless: BooleanInput = false;
  @Input() placeholder: string;
  @Input() useCustomRecipients: boolean = false;
  @Input() customRecipients: MailRecipient<any>[];
  @Input() customer: Customer;

  public companyUsers$: Observable<MailRecipient<User>[]>;
  public customerContacts$: Observable<MailRecipient<CustomerContact>[]>;

  public value: MailRecipientLike[] = [];
  public disabled = false;
  private _onChange = (emails: string[]) => { };
  private _onTouched = () => { };
  private _onValidatorChange = () => { };

  constructor(
    @Inject(CWT_FORM_CONFIG) private formConfig: ICwtFormConfig,
    private recipientService: MailRecipientsService,
  ) { }

  ngOnInit(): void {
    if (!this.useCustomRecipients) {
      this.companyUsers$ = this.recipientService.getCompanyUserRecipients();
      this.customerContacts$ = this.recipientService.getCustomerContactRecipients(this.customer);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChangesInIds(changes, ['customer'])) this.customerContacts$ = this.recipientService.getCustomerContactRecipients(this.customer);
  }

  writeValue(obj: string[]): void {
    this.value = Array.isArray(obj) ? obj : [];
  }

  onMailChanged(value: MailRecipientLike[]) {
    const emails = value.map(item => {
      if (typeof item === 'string') return item;
      if (item.emailValid) return item.email;
      return null;
    })

    this._onChange(emails.filter(isset));
  }

  setDisabledState(isDisabled: boolean): void { this.disabled = isDisabled; }
  registerOnChange(fn: any): void { this._onChange = fn; }
  registerOnTouched(fn: any): void { this._onTouched = fn; }
  registerOnValidatorChange(fn: () => void): void { this._onValidatorChange = fn; }

  validate(control: AbstractControl<string[]>): ValidationErrors {
    if (!control?.value?.length) return null;
    const invalidEmails = control.value.filter(mail => !this.formConfig.emailValidatorRegexp.test(mail ?? ''));
    if (invalidEmails.length) return { invalid: { emails: invalidEmails } };
    return null;
  }
}
