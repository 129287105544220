import { Customer, Invoice, Quote, CompanyTemplate, Conformity, Template } from "../../models";

export type MailRecipient<D> = {
    emailValid: boolean;
    origin: string;
    email: string;
    label: string;
    data: D;
}

export type MailParams = {
    quote?: Quote;
    invoice?: Invoice;
    customer?: Customer;
    conformity?: Conformity;
};

export type MailPreviewOptions = {
    template: Template;
    params?: MailParams;
}

export type MailSendOptions<M = any> = MailPreviewOptions & {
    emails: string[];
    subject: string;
    content: string;
} & M;

export enum MailAction {
    SendQuote = 'sendQuote',
    SendInvoice = 'sendInvoice',
    SendCustomerConformityEvent = 'sendCustomerConformityEvent'
}