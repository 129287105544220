import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { asArray, getId, hasSomeChanges, isset } from '@cawita/core-front';
import { CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { sameIds } from '@hints/utils/mongo';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { Observable, of } from 'rxjs';
import { CompanyTemplate } from '../../../models';
import { ConstraintResolveOptions } from '../services/mailing-constraint.service';

@Component({
  selector: 'cwt-mail-template-input',
  templateUrl: './mail-template-input.component.html',
  styleUrls: ['./mail-template-input.component.less'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MailTemplateInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MailTemplateInputComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailTemplateInputComponent implements OnChanges, ControlValueAccessor, Validator {
  public CompareIds = sameIds;

  @Input() @InputBoolean() nzBorderless: BooleanInput = false;
  @Input() placeholder: string;
  @Input() useCases: string | string[];
  @Input() resolveConstraint: (useCase: string, options: ConstraintResolveOptions) => Promise<void>;
  @ViewChild(NzSelectComponent) private select: NzSelectComponent;

  public templates$: Observable<CompanyTemplate[]>;
  public value: CompanyTemplate = null;
  public disabled = false;
  private _onChange = (template: CompanyTemplate) => { };
  private _onTouched = () => { };
  private _onValidatorChange = () => { };

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(CwtCrudContract.get(CompanyTemplate)) private templateCrud: CwtCrudService<CompanyTemplate>,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['useCases'], true)) {
      if (!this.useCases?.length) this.templates$ = of([]);
      else this.templates$ = this.templateCrud.getArray({ useCase: { in: asArray(this.useCases ?? []) } });
    }
  }

  writeValue(obj: any): void {
    this.value = isset(getId(obj)) ? obj : null;
    this.cdRef.markForCheck();
  }

  async onTemplateChange(value: CompanyTemplate) {
    if (!isset(value)) {
      this._setSelected(null);
      this._onChange(null);
      return;
    }

    const currentValue = this.value;
    if (!this.resolveConstraint) {
      this._setSelected(value);
      this._onChange(value);
      return;
    }

    await this.resolveConstraint(value.useCase, {
      constraintsFound: () => {
        this._setSelected(null);
        this._onChange(null);
      },
      constraintsUnresolved: () => {
        this._setSelected(currentValue);
        this._onChange(currentValue);
      },
      constraintsResolved: () => {
        this._setSelected(value)
        this._onChange(value);
      }
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }
  registerOnChange(fn: any): void { this._onChange = fn; }
  registerOnTouched(fn: any): void { this._onTouched = fn; }
  registerOnValidatorChange(fn: () => void): void { this._onValidatorChange = fn; }

  validate(control: AbstractControl<any, any>): ValidationErrors { return null; }

  private _setSelected(template: CompanyTemplate) {
    this.value = template;
    this.select.writeValue(template);
    this.cdRef.markForCheck();
  }
}
