<nz-select nzMode="tags" nzSelectOnTab
    class="rounded-tags"
    [nzBorderless]="nzBorderless"
    [(ngModel)]="value"
    [nzDisabled]="disabled"
    [nzCustomTemplate]="customTag"
    (ngModelChange)="onMailChanged($event)"
    [nzTokenSeparators]="[' ', ';', ',']"
    [nzPlaceHolder]="placeholder">
    <ng-container *ngIf="!useCustomRecipients">
        <ng-container *cwtLet="customerContacts$ | async as customerContacts">
            <nz-option-group *ngIf="customerContacts?.length" [nzLabel]="'alert.mailing-customer-contacts' | translate">
                <nz-option *ngFor="let recipient of customerContacts; cwtTrackById: 'data'" [nzValue]="recipient" [nzDisabled]="!recipient.emailValid" nzCustomContent><cwt-recipient-option [recipient]="recipient" /></nz-option>
            </nz-option-group>
        </ng-container>
        <ng-container *cwtLet="companyUsers$ | async as companyUsers">
            <nz-option-group *ngIf="companyUsers?.length" [nzLabel]="'alert.mailing-company-users' | translate">
                <nz-option *ngFor="let recipient of companyUsers; cwtTrackById: 'data'" [nzValue]="recipient" [nzDisabled]="!recipient.emailValid" nzCustomContent><cwt-recipient-option [recipient]="recipient" /></nz-option>
            </nz-option-group>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="useCustomRecipients">
        <nz-option *ngFor="let recipient of customRecipients; cwtTrackById: 'data'" [nzValue]="recipient" [nzDisabled]="!recipient.emailValid" nzCustomContent><cwt-recipient-option [recipient]="recipient" /></nz-option>
    </ng-container>

</nz-select>

<ng-template #customTag let-selected>
    <div [ngSwitch]="selected.nzValue?.origin">
        <cwt-contact-recipient *ngSwitchCase="'customer-contact'" [recipient]="selected.nzValue"></cwt-contact-recipient>
        <cwt-user-recipient *ngSwitchCase="'company-user'" [recipient]="selected.nzValue"></cwt-user-recipient>
        <span *ngSwitchDefault>{{selected.nzLabel}}</span>
    </div>
</ng-template>
<ng-template #recipientOption let-recipient>
    <span [nz-tooltip]="'alert.recipient-email-invalid' | translate" [nzTooltipTrigger]="recipient.emailValid ? null : 'hover'">
        {{recipient.label}} - {{recipient.email}}
    </span>
</ng-template>