import { Injectable, inject } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiService } from '@cawita/core-front/api';
import { CompanyTemplateUseCase } from '@shared/models';
import { MailActionHandler, MailPreviewOptions, MailSendOptions } from '@shared/modules/mailing';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SendInvoiceMailAdapter extends MailActionHandler {
    protected api = inject(CwtApiService);
    getTemplatesUseCases() { return [CompanyTemplateUseCase.SendInvoice, CompanyTemplateUseCase.SendInvoiceAttachment]; }

    preview(options: MailPreviewOptions): Observable<any> {
        return this.api.post(`company/:companyId/invoice/${getId(options.params.invoice)}/action/preview`, {
            template: getId(options.template)
        });
    }

    send(options: MailSendOptions): Observable<any> {
        return this.api.post(`company/:companyId/invoice/${getId(options.params.invoice)}/action/send`, {
            emails: options.emails,
            template: getId(options.template),
            content: options.content,
            subject: options.subject
        });
    }
}