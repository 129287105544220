import { Injectable, inject } from '@angular/core';
import { ConstraintOptions, MailConstraintHandler, MailParams } from '@shared/modules/mailing';
import { InvoiceApi } from '@shared/services/apis/invoice.api';
import { QuoteApi } from '@shared/services/apis/quote.api';
import { map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IsVisibleConstraintHandler extends MailConstraintHandler {
    protected invoiceApi = inject(InvoiceApi);
    protected quoteApi = inject(QuoteApi);

    public check(params: MailParams, options: ConstraintOptions) {
        if (params.invoice) return params.invoice.isVisibleToCustomer === true;
        if (params.quote) return params.quote.isVisibleToCustomer === true;
        return false;
    }
    public resolve(params: MailParams, options: ConstraintOptions) {
        if (params.invoice) return this.invoiceApi.setVisibleToCustomer(params.invoice, true).pipe(tap(r => r.isVisibleToCustomer = r.isVisibleToCustomer), map(r => r.isVisibleToCustomer));
        if (params.quote) return this.quoteApi.setVisibleToCustomer(params.quote, true).pipe(tap(r => r.isVisibleToCustomer = r.isVisibleToCustomer), map(r => r.isVisibleToCustomer));
        return false;
    }
}